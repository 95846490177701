import React from "react";
import "./PortfolioSummary.css";

const PortfolioSummary = ({
  portfolioValue,
  highValue,
  drawdown,
  retracement,
}) => {
  return (
    <div className="portfolio-summary">
      <div className="portfolio-item">
        <h3>Total Value</h3>
        <p>
          $
          {Number(portfolioValue).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </p>
      </div>
      <div className="portfolio-item">
        <h3>High Value</h3>
        <p>
          $
          {Number(highValue).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </p>
      </div>
      <div className="portfolio-item">
        <h3>Drawdown</h3>
        <p>-{drawdown}%</p>
      </div>
      <div className="portfolio-item">
        <h3>Retracement</h3>
        <p>
          $
          {Number(retracement).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </p>
      </div>
    </div>
  );
};

export default PortfolioSummary;
