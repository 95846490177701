import React from "react";
import PortfolioSummary from "./PortfolioSummary.js";
import AccountList from "./AccountList.js";
import AddAccount from "./AddAccount.js";
import PortfolioOverlay from "./PortfolioOverlay.js";
import "./Portfolio.css";

const Portfolio = ({
  user,
  portfolioValue,
  highValue,
  drawdown,
  highValueDifference,
  onPortfolioValueChange,
  onHighValueChange,
}) => {
  return (
    <div className="portfolio-container">
      {!user && <PortfolioOverlay />}
      <PortfolioSummary
        portfolioValue={portfolioValue}
        highValue={highValue}
        drawdown={drawdown}
        retracement={highValueDifference}
      />
      <AccountList
        user={user}
        onPortfolioValueChange={onPortfolioValueChange}
        onHighValueChange={onHighValueChange}
      />
      <AddAccount user={user} />
    </div>
  );
};

export default Portfolio;
