import { supabase } from "../../supabaseClient";

const SignOut = () => {
  const handleSignOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.log("Error signing out:", error.message);
    } else {
      alert("Signed out successfully");
      window.location.reload();
    }
  };

  return (
    <button className="login-button" onClick={handleSignOut}>
      Sign Out
    </button>
  );
};

export default SignOut;
