import React from "react";
import { supabase } from "../../supabaseClient";
import "./GoogleSignIn.css";

function GoogleSignIn() {
  const signInWithGoogle = async () => {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: "google",
    });
    if (error) {
      console.error("Error signing in with Google:", error.message);
    }
  };

  return (
    <button className="google-sign-in-button" onClick={signInWithGoogle}>
      <img
        src="https://developers.google.com/identity/images/g-logo.png"
        alt="Google Logo"
        className="google-logo"
      />
      Sign in with Google
    </button>
  );
}

export default GoogleSignIn;
