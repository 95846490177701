import React, { useState } from "react";

const QuotesTable = ({ data }) => {
  const [sortConfig, setSortConfig] = useState({
    key: "aum",
    direction: "desc",
  });

  // Sorting logic
  const sortedData = React.useMemo(() => {
    if (!sortConfig.key) return data;

    const sorted = [...data].sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      // Convert values for specific columns
      if (
        sortConfig.key === "aum" ||
        sortConfig.key === "price" ||
        sortConfig.key === "high52Week" ||
        sortConfig.key === "drawdown" ||
        sortConfig.key === "retracement"
      ) {
        aValue = parseFloat(aValue.toString().replace(/[$,%]/g, "")) || 0;
        bValue = parseFloat(bValue.toString().replace(/[$,%]/g, "")) || 0;
      }
      // Handle comparison
      if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    });

    return sorted;
  }, [data, sortConfig]);

  // Handle sorting state update
  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "asc"
          ? "desc"
          : "asc",
    }));
  };

  // Arrow indicator for sorting
  const getArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? " ↑" : " ↓";
    }
    return " ↕︎";
  };

  // Handle empty or invalid data
  if (!Array.isArray(data) || data.length === 0) {
    return <p>No data to display.</p>;
  }

  // Determine row background color
  const getBackgroundColor = (lastPrice, high52Week) => {
    let backgroundColor = "#fff"; // Default background
    if (lastPrice && high52Week) {
      const pricePercentage = (lastPrice / high52Week) * 100;

      if (pricePercentage >= 100) {
        backgroundColor = "lightgreen"; // Green if at or above 100%
      } else if (pricePercentage >= 80) {
        // Linear interpolation for red shade between 80% and 100%
        const intensity = ((pricePercentage - 80) / 20) * 255;
        backgroundColor = `rgb(255, ${Math.floor(intensity)}, ${Math.floor(
          intensity
        )})`;
      } else {
        backgroundColor = "rgb(255, 0, 0)";
      }
    }
    return backgroundColor;
  };

  return (
    <div className="position-table-container">
      <table className="position-table">
        <thead>
          <tr>
            <th onClick={() => handleSort("symbol")}>
              Symbol<span>{getArrow("symbol")}</span>
            </th>
            <th onClick={() => handleSort("name")}>
              Fund name<span>{getArrow("name")}</span>
            </th>
            <th onClick={() => handleSort("assetClass")}>
              Class<span>{getArrow("assetClass")}</span>
            </th>
            <th onClick={() => handleSort("aum")}>
              AUM ($B)<span>{getArrow("aum")}</span>
            </th>
            <th onClick={() => handleSort("price")}>
              Price<span>{getArrow("price")}</span>
            </th>
            <th onClick={() => handleSort("high52Week")}>
              52W High Price<span>{getArrow("high52Week")}</span>
            </th>
            <th onClick={() => handleSort("drawdown")}>
              Drawdown %<span>{getArrow("drawdown")}</span>
            </th>
            <th onClick={() => handleSort("retracement")}>
              Retracement %<span>{getArrow("retracement")}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((row, index) => (
            <tr
              key={index}
              style={{
                backgroundColor: getBackgroundColor(row.price, row.high52Week),
              }}
            >
              <td>{row.symbol}</td>
              <td>{row.name}</td>
              <td>{row.assetClass}</td>
              <td>${row.aum}</td>
              <td>${row.price}</td>
              <td>${row.high52Week}</td>
              <td>{row.drawdown}%</td>
              <td>{row.retracement}%</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default QuotesTable;
