import React, { useEffect, useState } from "react";
import { supabase } from "../supabaseClient";
import "./Home.css";
import StockDrawdown from "../components/StockDrawdown.js";
import Portfolio from "../components/portfolio/Portfolio.js";

const Home = () => {
  const [user, setUser] = useState(null);
  const [portfolioValue, setPortfolioValue] = useState(0);
  const [highValue, setHighValue] = useState(0);

  useEffect(() => {
    const fetchUser = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser(); // Fetch the authenticated user
      setUser(user);
    };

    fetchUser();
  }, []);

  const handlePortfolioValueChange = (sum) => {
    setPortfolioValue(sum.toFixed(2));
  };

  const handleHighValueChange = (sum) => {
    setHighValue(sum.toFixed(2));
  };

  const drawdown =
    highValue > 0 ? ((1 - portfolioValue / highValue) * 100).toFixed(2) : 0;

  const highValueDifference =
    highValue > 0 ? (highValue - portfolioValue).toFixed(2) : 0;

  return (
    <div className="home">
      <div className="index-drawdowns">
        <div className="page-header">
          <h1 className="h-1">Home</h1>
        </div>
        <div className="stock-drawdown-container">
          <StockDrawdown index="S&P500" ticker="SPY"></StockDrawdown>
          <StockDrawdown index="Nasdaq 100" ticker="QQQ"></StockDrawdown>
          <StockDrawdown index="Dow Jones" ticker="DIA"></StockDrawdown>
          <StockDrawdown index="Russell 2000" ticker="IWM"></StockDrawdown>
        </div>
      </div>
      <div className="my-portfolio">
        <h1 className="h-1">My Portfolio</h1>
      </div>
      <div>
        <Portfolio
          user={user}
          portfolioValue={portfolioValue}
          highValue={highValue}
          drawdown={drawdown}
          highValueDifference={highValueDifference}
          onPortfolioValueChange={handlePortfolioValueChange}
          onHighValueChange={handleHighValueChange}
        />
      </div>
    </div>
  );
};

export default Home;
