import React, { useEffect, useState } from "react";
import logo from "./media_assets/full_logo_2.png";
import "./Navbar.css";
import SignIn from "./auth/signIn.js";
import SignUp from "./auth/signUp.js";
import SignOut from "./auth/signOut.js";
import { supabase } from "../supabaseClient";

const Navbar = () => {
  const [user, setUser] = useState(null);
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser(); // Fetch the authenticated user if exists
      setUser(user);
    };

    fetchUser();
  }, []);

  const toggleSignInModal = () => setIsSignInModalOpen(!isSignInModalOpen);
  const toggleSignUpModal = () => setIsSignUpModalOpen(!isSignUpModalOpen);

  return (
    <nav className="navbar">
      <div className="container">
        <div className="logo">
          <a href="https://stockdrawdowns.com">
            <img src={logo} alt="Logo" className="logo-img" />
          </a>
        </div>
        <div className="auth-links">
          {user ? (
            <>
              <SignOut />
            </>
          ) : (
            <>
              <button className="login-button" onClick={toggleSignInModal}>
                Log In
              </button>
              <button className="signup-button" onClick={toggleSignUpModal}>
                Sign Up
              </button>
            </>
          )}
        </div>
      </div>
      {isSignInModalOpen && <SignIn closeModal={toggleSignInModal} />}
      {isSignUpModalOpen && <SignUp closeModal={toggleSignUpModal} />}
    </nav>
  );
};

export default Navbar;
