import { useState } from "react";
import { supabase } from "../../supabaseClient";
import "./signUp.css";
import GoogleSignIn from "./GoogleSignIn.js";

const SignUp = ({ closeModal }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const handleSignUp = async (e) => {
    e.preventDefault();
    const { error } = await supabase.auth.signUp({ email, password });
    if (error) setError(error.message);
    else {
      alert("Check your email for the confirmation link");
      closeModal(); // Close modal on successful sign-up
    }
  };

  return (
    <div className="sign-up-modal">
      <div className="sign-up-modal-content">
        <button className="close-button" onClick={closeModal}>
          ×
        </button>
        <h2 className="modal-title">Create an account</h2>
        <form onSubmit={handleSignUp} className="sign-up-form">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
          <button type="submit">Sign Up</button>
          {error && <p className="error-text">{error}</p>}
        </form>
        <div className="divider"></div>
        <GoogleSignIn />
        <p></p>
        <p className="fine-print">
          By creating an account, you agree to the Terms of Service and Privacy
          Policy
        </p>
      </div>
    </div>
  );
};

export default SignUp;
