// components/AddPosition.js
import { useState } from "react";
import { supabase } from "../../supabaseClient";

const AddPosition = ({ accountId, onPositionAdded }) => {
  const [symbol, setSymbol] = useState("");
  const [quantity, setQuantity] = useState("");
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const validateInputs = () => {
    const symbolRegex = /^[A-Za-z]{1,5}$/;
    const quantityNumber = parseInt(quantity, 10);

    if (!symbolRegex.test(symbol)) {
      return "Symbol must be between 1 and 5 letters with no numbers.";
    }
    if (
      isNaN(quantityNumber) ||
      quantityNumber < 1 ||
      quantityNumber > 1000000
    ) {
      return "Quantity must be a whole number between 1 and 1,000,000.";
    }
    return null;
  };

  const handleAddPosition = async () => {
    const validationError = validateInputs();
    if (validationError) {
      setError(validationError);
      return;
    }

    const { error } = await supabase.from("positions").insert([
      {
        account_id: accountId,
        symbol: symbol.toUpperCase(),
        quantity: parseInt(quantity, 10),
        type: 1, // Default type
      },
    ]);

    if (error) {
      setError(error.message);
    } else {
      setMessage("Position added successfully!");
      setSymbol("");
      setQuantity("");
      onPositionAdded(); // Notify parent to refresh the account list
    }
  };

  return (
    <div>
      <h4>Add Position</h4>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {message && <p style={{ color: "green" }}>{message}</p>}
      <div>
        <input
          type="text"
          value={symbol}
          onChange={(e) => setSymbol(e.target.value)}
          placeholder="Symbol"
        />
      </div>
      <div>
        <input
          type="number"
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
          placeholder="Quantity"
        />
      </div>
      <button onClick={handleAddPosition}>Add Position</button>
    </div>
  );
};

export default AddPosition;
