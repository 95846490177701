import { useState } from "react";
import "./EditAccountModal.css";

const EditAccountModal = ({ account, onSave, onClose }) => {
  const [newName, setNewName] = useState(account.name);

  const handleSave = () => {
    onSave(newName);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Edit Account Name</h2>
        <input
          type="text"
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
        />
        <button onClick={handleSave}>Save</button>
        <button onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
};

export default EditAccountModal;
