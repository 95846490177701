import React, { useState, useEffect } from "react";
import "./Stocks.css";

import { supabase } from "../supabaseClient";

const Stocks = () => {
  const [dowData, setDowData] = useState([]);
  const [sp500Data, setSp500Data] = useState([]);
  const [nasdaqData, setNasdaqData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data, error } = await supabase.functions.invoke(
          "getAllIndexTopHoldings"
        );

        if (error) {
          throw new Error(`Supabase Function Error: ${error.message}`);
        }

        // Assuming `data` is directly returned as JSON from your Supabase Edge Function
        setDowData(data.dowData);
        setSp500Data(data.sp500Data);
        setNasdaqData(data.nasdaqData);
      } catch (error) {
        console.error("Error fetching stock data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const getBackgroundColor = (drawdown) => {
    let backgroundColor = "#fff"; // Default background
    if (drawdown !== null) {
      if (drawdown <= 0) {
        backgroundColor = "lightgreen"; // Green for non-negative drawdowns
      } else if (drawdown <= 20) {
        // Linear interpolation for red shade between 0 and 20
        const intensity = Math.floor((drawdown / 20) * 255);
        backgroundColor = `rgb(255, ${255 - intensity}, ${255 - intensity})`;
      } else {
        backgroundColor = "rgb(255, 0, 0)"; // Bright red for drawdowns > 20
      }
    }
    return backgroundColor;
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="page-header">
      <h1 className="h-1">Stocks by Index</h1>

      {/* Render Dow Jones Table */}
      <div className="position-table-container">
        <h2>Dow Jones</h2>
        <table className="position-table">
          <thead>
            <tr>
              <th>Symbol</th>
              <th>Name</th>
              <th>Weight</th>
              <th>Drawdown</th>
            </tr>
          </thead>
          <tbody>
            {dowData.map((item, index) => (
              <tr
                key={index}
                style={{
                  backgroundColor: getBackgroundColor(item.drawdown),
                }}
              >
                <td>{item.symbol}</td>
                <td>{item.name}</td>
                <td>{item.weight.toFixed(2)}%</td>
                <td>
                  {item.drawdown !== null
                    ? `${item.drawdown.toFixed(2)}%`
                    : "N/A"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Render S&P 500 Table */}
      <div className="position-table-container">
        <h2>S&P 500</h2>
        <table className="position-table">
          <thead>
            <tr>
              <th>Symbol</th>
              <th>Name</th>
              <th>Weight</th>
              <th>Drawdown</th>
            </tr>
          </thead>
          <tbody>
            {sp500Data.map((item, index) => (
              <tr
                key={index}
                style={{
                  backgroundColor: getBackgroundColor(item.drawdown),
                }}
              >
                <td>{item.symbol}</td>
                <td>{item.name}</td>
                <td>{item.weight.toFixed(2)}%</td>
                <td>
                  {item.drawdown !== null
                    ? `${item.drawdown.toFixed(2)}%`
                    : "N/A"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Render Nasdaq Table */}
      <div className="position-table-container">
        <h2>Nasdaq</h2>
        <table className="position-table">
          <thead>
            <tr>
              <th>Symbol</th>
              <th>Name</th>
              <th>Weight</th>
              <th>Drawdown</th>
            </tr>
          </thead>
          <tbody>
            {nasdaqData.map((item, index) => (
              <tr
                key={index}
                style={{
                  backgroundColor: getBackgroundColor(item.drawdown),
                }}
              >
                <td>{item.symbol}</td>
                <td>{item.name}</td>
                <td>{item.weight.toFixed(2)}%</td>
                <td>
                  {item.drawdown !== null
                    ? `${item.drawdown.toFixed(2)}%`
                    : "N/A"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <h1 className="h-1">Themes</h1>
    </div>
  );
};

export default Stocks;
