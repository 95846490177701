import React from "react";
import "./PortfolioOverlay.css";

const PortfolioOverlay = () => {
  return (
    <div className="portfolio-overlay">
      <div className="portfolio-overlay-content">
        <p>
          🔒 Your positions will disappear. Log In or Sign Up for a FREE account
          to save them.{" "}
        </p>
      </div>
    </div>
  );
};

export default PortfolioOverlay;
