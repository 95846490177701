import React from "react";
import { Link } from "react-router-dom";
import "./SideNav.css";

const SideNav = () => {
  return (
    <nav className="sidenav">
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/indexes">Indexes</Link>
        </li>
        <li>
          <Link to="/etfs">ETFs</Link>
        </li>
        <li>
          <Link to="/stocks">Stocks</Link>
        </li>
        <li>
          <Link to="/crypto">Crypto</Link>
        </li>
        <li>
          <Link to="/bonds">Bonds</Link>
        </li>
        <li>
          <Link to="/commodities">Commodities</Link>
        </li>
        <li>
          <Link to="/themes">Themes</Link>
        </li>
        <li>
          <Link to="/newsletter">Newsletter</Link>
        </li>
        <li>
          <Link to="/watchlist">Watchlist</Link>
        </li>
        <li>
          <Link to="/portfolio">My Portfolio</Link>
        </li>
      </ul>
    </nav>
  );
};

export default SideNav;
