import React from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="privacyPolicy">
      <h1 className="h-1">Privacy Policy</h1>
      <div className="overlap">
        <p className="overlap_text_pp">
          At stockdrawdowns.com, we are committed to protecting and respecting
          your privacy. This Privacy Policy outlines how we collect, use,
          disclose, and protect your personal information when you interact with
          our website and services.
        </p>
        <h2 className="h-2">1. Information We Collect</h2>
        <p className="overlap_text_pp">
          We may collect the following personal information from you when you
          sign up through our website:
        </p>
        <ul className="overlap_text_pp">
          <li>First Name</li>
          <li>Last Name</li>
          <li>Email Address</li>
          <li>Phone Number</li>
          <li>State of Residence</li>
          <li>Investable Assets Details</li>
        </ul>
        <h2 className="h-2">2. How We Use Your Information</h2>
        <p className="overlap_text_pp">
          We use the information provided to us to deliver relevant marketing
          materials, promotions, updates, newsletters, and offers. The data
          collected helps us understand your needs better and tailor our
          communications to suit your interests.
        </p>
        <h2 className="h-2">3. Data Security</h2>
        <p className="overlap_text_pp">
          We are committed to ensuring that your information is secure. We have
          implemented appropriate physical, electronic, and managerial
          procedures to safeguard and secure the data we collect online to
          prevent unauthorized access, maintain data accuracy, and ensure the
          proper use of information.
        </p>
        <h2 className="h-2">4. Disclosure to Third Parties</h2>
        <p className="overlap_text_pp">
          We may share or sell your email address to carefully selected
          third-party partners for marketing purposes. You can opt-out of such
          data sharing at any time by contacting support@stockdrawdowns.com. We
          may share your data with trusted third-party service providers who
          assist us in operating our website, conducting business, or servicing
          you, as long as those parties agree to keep this information
          confidential.
        </p>
        <h2 className="h-2">5. Legal Requirements</h2>
        <p className="overlap_text_pp">
          We may access, preserve, and disclose your personal information if
          required to do so by law or in good faith belief that such action is
          necessary to comply with legal obligations, protect and defend our
          rights or property, or to investigate potential violations of our
          Terms of Service.
        </p>
        <h2 className="h-2">6. Data Retention</h2>
        <p className="overlap_text_pp">
          We will retain your personal information for as long as necessary to
          fulfill the purposes outlined in this Privacy Policy, unless a longer
          retention period is required or permitted by law.
        </p>
        <h2 className="h-2">7. Cookies and Tracking Technologies</h2>
        <p className="overlap_text_pp">
          Our website may use cookies and tracking technologies to enhance user
          experience and collect information about how visitors use our site.
          You have the option to disable cookies through your browser settings;
          however, this may impact your ability to access certain features on
          our website.
        </p>
        <h2 className="h-2">8. Children's Privacy</h2>
        <p className="overlap_text_pp">
          Our services are not intended for individuals under the age of 18. We
          do not knowingly collect or solicit personal information from
          children. If you are under 18, please do not use our services or
          provide any personal information.
        </p>
        <h2 className="h-2">9. Your Choices</h2>
        <p className="overlap_text_pp">
          You have the right to access, correct, or delete your personal
          information that we hold. If you wish to exercise any of these rights,
          please contact us using the information provided at the end of this
          Privacy Policy.
        </p>
        <h2 className="h-2">10. Changes to the Privacy Policy</h2>
        <p className="overlap_text_pp">
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or for other operational, legal, or regulatory
          reasons. We encourage you to review this page periodically for the
          latest information on our privacy practices.
        </p>
        <h2 className="h-2">11. Contact Us</h2>
        <p className="overlap_text_pp">
          If you have any questions, concerns, or requests regarding this
          Privacy Policy or the use of your personal information, please contact
          support@stockdrawdowns.com
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
