import { useEffect, useState, useCallback } from "react";
import { supabase } from "../../supabaseClient";
import AddPosition from "./AddPosition";
import PositionTable from "./PositionTable";
import EditAccountModal from "./EditAccountModal";
import edit_square from "../media_assets/edit_square.png";
import SignUp from "../auth/signUp.js";
import "./AccountList.css";

const AccountList = ({ user, onPortfolioValueChange, onHighValueChange }) => {
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [portfolioValue, setPortfolioValue] = useState(0);
  const [highValue, setHighValue] = useState(0);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentAccount, setCurrentAccount] = useState(null);
  const [showSignUpModal, setShowSignUpModal] = useState(false);

  const fetchAccounts = useCallback(async () => {
    try {
      const { data, error } = await supabase
        .from("accounts")
        .select("*")
        .eq("user_id", user.id);

      if (error) throw error;
      setAccounts(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (user) fetchAccounts();
  }, [user, fetchAccounts]);

  const handleSignUpClick = () => {
    setShowSignUpModal(true);
  };

  const handleEditClick = (account) => {
    setCurrentAccount(account);
    setShowEditModal(true);
  };

  const handleEditSave = async (newName) => {
    if (!currentAccount) return;

    const { error } = await supabase
      .from("accounts")
      .update({ name: newName })
      .eq("id", currentAccount.id);

    if (!error) {
      fetchAccounts(); // Refresh accounts after saving
      setShowEditModal(false); // Close the modal
    } else {
      console.error("Error updating account name:", error.message);
    }
  };

  const handleValueSumChange = (sum) => {
    setPortfolioValue((prevTotal) => prevTotal + sum);
  };

  const handleHighValueSumChange = (sum) => {
    setHighValue((prevTotal) => prevTotal + sum);
  };

  useEffect(() => {
    onPortfolioValueChange(portfolioValue);
  }, [portfolioValue, onPortfolioValueChange]);

  useEffect(() => {
    onHighValueChange(highValue);
  }, [highValue, onHighValueChange]);

  if (!user) {
    return (
      <div className="account-list">
        <ul>
          <li className="account-container">
            <div className="account-header">
              <h3>My Account #1</h3>
              <button
                onClick={() => handleSignUpClick()}
                className="edit-button"
              >
                <img src={edit_square} className="edit-square" alt="Edit" />
              </button>
            </div>
            <PositionTable
              accountId="sample"
              isDemo={!user}
              onPortfolioValueSumChange={handleValueSumChange}
              onHighValueSumChange={handleHighValueSumChange}
            />
            <AddPosition accountId="sample" isDemo={!user} />
          </li>
        </ul>
        {showSignUpModal && (
          <SignUp closeModal={() => setShowSignUpModal(false)} />
        )}
      </div>
    );
  }

  if (loading) return <p>Loading accounts...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="account-list">
      {accounts.length > 0 ? (
        <ul>
          {accounts.map((account) => (
            <li key={account.id} className="account-container">
              <div className="account-header">
                <h3>{account.name} </h3>
                <button
                  onClick={() => handleEditClick(account)}
                  className="edit-button"
                >
                  <img src={edit_square} className="edit-square" alt="Edit" />
                </button>
              </div>
              <PositionTable
                accountId={account.id}
                onPortfolioValueSumChange={handleValueSumChange}
                onHighValueSumChange={handleHighValueSumChange}
              />
              <AddPosition
                accountId={account.id}
                onPositionAdded={fetchAccounts} // Refresh the list when a position is added
              />
            </li>
          ))}
        </ul>
      ) : (
        <p>You don't have any accounts yet.</p>
      )}
      {showEditModal && (
        <EditAccountModal
          account={currentAccount}
          onSave={handleEditSave}
          onClose={() => setShowEditModal(false)}
        />
      )}
    </div>
  );
};

export default AccountList;
