import { useState } from "react";
import { supabase } from "../../supabaseClient";
import "./AddAccount.css";

const AddAccount = ({ user }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleSaveAccount = async () => {
    if (!accountName) {
      setError("Account name cannot be empty.");
      return;
    }

    const { error } = await supabase
      .from("accounts")
      .insert([{ user_id: user.id, name: accountName }]);

    if (error) {
      setError(error.message);
    } else {
      setMessage("Account added successfully!");
      setAccountName(""); // Reset input field
      closeModal(); // Close the modal
    }
  };

  return (
    <div>
      <button onClick={openModal}>Add Account</button>

      {isModalOpen && (
        <div className="modal-backdrop">
          <div className="modal">
            <h2>Add a name for the new account</h2>
            <input
              type="text"
              value={accountName}
              onChange={(e) => setAccountName(e.target.value)}
              placeholder="Enter account name"
            />
            {error && <p className="error">{error}</p>}
            {message && <p className="message">{message}</p>}
            <div className="button-group">
              <button onClick={handleSaveAccount}>Save</button>
              <button onClick={closeModal}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddAccount;
