import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import SideNav from "./components/SideNav";

import PrivacyPolicy from "./pages/PrivacyPolicy";
import Home from "./pages/Home";
import Indexes from "./pages/Indexes";
import Etfs from "./pages/Etfs";
import Stocks from "./pages/Stocks";

const App = () => {
  return (
    <Router>
      <Navbar />
      <div className="app-container">
        <SideNav />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/indexes" element={<Indexes />} />
            <Route path="/etfs" element={<Etfs />} />
            <Route path="/stocks" element={<Stocks />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        </main>
      </div>
      <Footer />
    </Router>
  );
};

export default App;
